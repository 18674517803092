import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';
import FullPageLoadingIndicator from '@/components/FullPageLoadingIndicator';
import AuthenticationError from '@/components/Oidc/AuthenticationError';

type Props = {
    children : JSX.Element;
};

const OidcSecure = ({children} : Props) : JSX.Element => {
    const {isAuthenticated, isLoading, loginWithRedirect, error} = useAuth0();

    useEffect(() => {
        if (isLoading || isAuthenticated || error) {
            return;
        }

        void loginWithRedirect();
    }, [isLoading, isAuthenticated, error, loginWithRedirect]);

    if (error) {
        return <AuthenticationError/>;
    }

    if (isAuthenticated) {
        return children;
    }

    return <FullPageLoadingIndicator/>;
};

export default OidcSecure;
