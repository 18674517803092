import {Alert, AlertTitle, Button} from '@mui/material';

type Props = {
    heading ?: string;
    message : string;
    onRetry : () => Promise<void> | void;
};

const RetryableError = ({heading, message, onRetry} : Props) : JSX.Element => (
    <Alert
        severity="error"
        action={(
            <Button color="inherit" size="small" onClick={onRetry}>
                Retry
            </Button>
        )}
    >
        <AlertTitle>{heading ?? 'Error'}</AlertTitle>
        {message}
    </Alert>
);

export default RetryableError;
