import ErrorIcon from '@mui/icons-material/Error';
import type {InputProps} from '@mui/material';
import {CircularProgress, InputAdornment, MenuItem} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import type {RhfTextFieldProps} from 'mui-rhf-integration/dist/RhfTextField';
import type {FieldPath, FieldValues} from 'react-hook-form';
import {useReferringPathologistsQuery} from '@/queries/referring-pathologist';

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<RhfTextFieldProps<TFieldValues, TName>, 'select' | 'InputProps'>;

const ReferringPathologistsSelect = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props : Props<TFieldValues, TName>) : JSX.Element => {
    const referringPathologistsQuery = useReferringPathologistsQuery();
    const inputProps : InputProps = {};

    if (!referringPathologistsQuery.data) {
        if (referringPathologistsQuery.isLoading) {
            inputProps.endAdornment = (
                <InputAdornment position="end">
                    <CircularProgress size={20}/>
                </InputAdornment>
            );
        } else if (referringPathologistsQuery.isError) {
            inputProps.endAdornment = (
                <InputAdornment position="end">
                    <ErrorIcon/>
                </InputAdornment>
            );
        }
    }

    return (
        <RhfTextField
            {...props}
            select={Boolean(referringPathologistsQuery.data)}
            disabled={Boolean(props.disabled) || !referringPathologistsQuery.data}
            InputProps={inputProps}
        >
            {referringPathologistsQuery.data && referringPathologistsQuery.data.map(pathologist => (
                <MenuItem key={pathologist.id} value={pathologist.id.toString()}>
                    {pathologist.firstName} {pathologist.middleInitial} {pathologist.lastName}
                </MenuItem>
            ))}
        </RhfTextField>
    );
};

export default ReferringPathologistsSelect;
