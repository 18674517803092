import type {UseMutationResult} from 'react-query';
import {useMutation, useQueryClient} from 'react-query';
import {useIdentity} from '@/components/IdentityProvider';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToCaseSubmission} from '@/mappers/case';
import type {CaseSubmission, RawCaseSubmission} from '@/types/case';
import type {ReferringPathologist} from '@/types/referring-pathologist';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type SubmitCaseValues = Omit<CaseSubmission, 'id' | 'referringPathologist'> & {
    mailingListId : string;
    referringPathologist : Pick<ReferringPathologist, 'id'>;
};

export const useSubmitCaseMutation = () : UseMutationResult<
    CaseSubmission,
    unknown,
    SubmitCaseValues
> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();
    const queryClient = useQueryClient();

    return useMutation(async (values : SubmitCaseValues) => {
        const response = await fetch(apiUrl(`/mailing-lists/${values.mailingListId}/cases`).toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to submit case');
        }

        const rawCaseSubmission = await response.json() as RawCaseSubmission;
        return mapRawToCaseSubmission(rawCaseSubmission);
    }, {
        onSuccess: (caseSubmission, values) => {
            void queryClient.invalidateQueries([
                activeIdentity.id.toString(),
                'mailing-lists',
                values.mailingListId,
                'cases',
            ]);
        },
    });
};

type DeleteCaseValues = {
    mailingListId : string;
    caseId : string;
};

export const useDeleteCaseMutation = () : UseMutationResult<
    void,
    unknown,
    DeleteCaseValues
> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();
    const queryClient = useQueryClient();

    return useMutation(async (values : DeleteCaseValues) => {
        const response = await fetch(
            apiUrl(`/mailing-lists/${values.mailingListId}/cases/${values.caseId}`).toString(),
            {
                method: 'DELETE',
                headers: {
                    'x-identity-id': activeIdentity.id.toString(),
                },
            }
        );

        if (!response.ok) {
            throw new SubmissionError('Failed to delete case');
        }
    }, {
        onSuccess: (result, values) => {
            void queryClient.invalidateQueries([
                activeIdentity.id.toString(),
                'mailing-lists',
                values.mailingListId,
                'cases',
            ]);
        },
    });
};

type GetReportPdfValues = {
    caseId : string;
};

export const useGetReportPdfMutation = () : UseMutationResult<
    Blob,
    unknown,
    GetReportPdfValues
> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();
    const queryClient = useQueryClient();

    return useMutation(async (values : GetReportPdfValues) => {
        const response = await fetch(apiUrl(`/cases/${values.caseId}/report-pdf`).toString(), {
            method: 'POST',
            headers: {
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to get report PDF');
        }

        return await response.blob();
    }, {
        onSuccess: () => {
            void queryClient.invalidateQueries([activeIdentity.id.toString(), 'cases', 'just-in']);
        },
    });
};
