import {useAuth0} from '@auth0/auth0-react';
import type {ReactNode} from 'react';
import {createContext, useCallback, useContext, useMemo} from 'react';
import {Navigate, useMatch, useNavigate} from 'react-router-dom';
import type {Identity} from '@/types/identity';
import {namespace} from '@/utils/jwt';

type IdentityContext = {
    activeIdentity : Identity;
    identities : Identity[];
    selectIdentity : (identityId : number) => void;
};

const identityContext = createContext<IdentityContext | null>(null);

type Props = {
    children ?: ReactNode | undefined;
};

export const useIdentity = () : IdentityContext => {
    const identity = useContext(identityContext);

    if (!identity) {
        throw new Error('Context used outside of provider');
    }

    return identity;
};

const IdentityProvider = ({children} : Props) : JSX.Element => {
    const {user} = useAuth0();
    const navigate = useNavigate();

    if (!user) {
        throw new Error('User must be signed in');
    }

    const identities = useMemo(() => {
        return JSON.parse(user[`${namespace}/identities`] as string) as Identity[];
    }, [user[`${namespace}/identities`]]);

    const pathMatch = useMatch({
        end: false,
        path: '/:identityId',
    });

    const storageKey = `${user.sub as string}:activeIdentity`;
    let identityId : number | undefined;
    let redirect = false;

    if (!pathMatch || !pathMatch.params.identityId) {
        redirect = true;

        const storedIdentityId = localStorage.getItem(storageKey);

        if (storedIdentityId) {
            identityId = parseInt(storedIdentityId, 10);
        }
    } else {
        identityId = parseInt(pathMatch.params.identityId, 10);
    }

    const activeIdentity = useMemo(() => {
        return identities.find(identity => identity.id === identityId);
    }, [identities, identityId]);

    const selectIdentity = useCallback((identityId : number) => {
        localStorage.setItem(storageKey, identityId.toString());
        navigate(`/${identityId}`);
    }, [navigate, storageKey]);

    const value = useMemo(() => ({
        activeIdentity,
        identities,
        selectIdentity,
    }), [activeIdentity, identities, selectIdentity]);

    if (!value.activeIdentity || redirect) {
        return <Navigate to={`/${(value.activeIdentity ?? identities[0]).id}`} replace/>;
    }

    return (
        <identityContext.Provider value={value as IdentityContext}>
            {children}
        </identityContext.Provider>
    );
};

export default IdentityProvider;
