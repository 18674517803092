import {useAuth0} from '@auth0/auth0-react';
import {Button, Card, CardActions, CardContent, CardHeader, Container, Typography} from '@mui/material';

const AuthenticationError = () : JSX.Element => {
    const {logout} = useAuth0();

    return (
        <Container sx={{mt: 4, mb: 4}}>
            <Card>
                <CardHeader title="Authentication failed"/>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        We were unable to complete your authentication. Please try again.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => {
                        window.location.href = window.location.origin;
                    }}>
                        Reload
                    </Button>
                    <Button size="small" onClick={() => {
                        logout({returnTo: window.location.origin});
                    }}>
                        Return to Login
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default AuthenticationError;
