import type {UseQueryResult} from 'react-query';
import {useQuery} from 'react-query';
import {useIdentity} from '@/components/IdentityProvider';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {ReferringPathologist} from '@/types/referring-pathologist';
import type {CollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const useReferringPathologistsQuery = () : UseQueryResult<ReferringPathologist[]> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();

    return useQuery([activeIdentity.id.toString(), 'referring_pathologists'], async ({signal}) => {
        const response = await fetch(apiUrl('/referring-pathologists').toString(), {
            signal,
            headers: {
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new Error('Unable to fetch referring pathologists');
        }

        return (await response.json() as CollectionResponse<ReferringPathologist>).items;
    });
};
