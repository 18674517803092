import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import PrintIcon from '@mui/icons-material/Print';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import logoImage from '@/assets/logo-opaque.jpg';
import {useIdentity} from '@/components/IdentityProvider';
import {useCaseSubmissionsQuery} from '@/queries/case';
import {useLatestMailingListQuery} from '@/queries/mailing-list';
import type {CaseSubmission} from '@/types/case';
import type {MailingList} from '@/types/mailing-list';

const dateFormatter = DateTimeFormatter.ofPattern('d MMMM y').withLocale(Locale.US);

const PrintButton = () : JSX.Element => {
    const [pdfLoading, setPdfLoading] = useState(false);
    const latestMailingListQuery = useLatestMailingListQuery();
    const casesQuery = useCaseSubmissionsQuery(latestMailingListQuery.data?.id);
    const {activeIdentity} = useIdentity();

    const downloadPdf = async (mailingList : MailingList, caseSubmissions : CaseSubmission[]) => {
        setPdfLoading(true);

        const pdfModules = await Promise.all([
            import('jspdf'),
            import('jspdf-autotable'),
        ]);
        const jsPDF = pdfModules[0].default;
        const autotable = pdfModules[1].default;

        const doc = new jsPDF({
            format: 'letter',
            unit: 'mm',
            orientation: 'portrait',
            putOnlyUsedFonts: true,
        });

        const logo = new Image();
        logo.src = logoImage;
        await new Promise(resolve => {
            logo.addEventListener('load', resolve);
        });

        doc.addImage(logo, 10, 10, 100, 19.47);

        doc.setFontSize(12);
        doc.text(
            'Submission List',
            10,
            32,
            {baseline: 'top'},
        );
        doc.setFontSize(10);
        doc.text(
            [
                'DermatopathologyConsultations.com',
                'c/o Atrius Health',
                '152 Second Avenue',
                'Needham Heights, MA 02494',
                'USA',
                '',
                'Tel: 781-292-7267',
                'Fax: 866-877-9094',
            ].join('\n'),
            10,
            39,
            {baseline: 'top'},
        );

        autotable(doc, {
            head: [['Shipping date', 'Carrier', 'Tracking number', 'Outside Practice']],
            body: [
                [
                    mailingList.shippingDate.format(dateFormatter),
                    mailingList.carrier,
                    mailingList.trackingNumber === '' ? 'Undefined' : mailingList.trackingNumber,
                    activeIdentity.orgUsername,
                ],
            ],
            margin: {top: 0, bottom: 0, left: 10, right: 10},
            startY: 80,
        });

        autotable(doc, {
            head: [['Case', 'Last Name', 'Pathologist', '# Slides', '# Blocks']],
            body: caseSubmissions.map(caseSubmission => [
                caseSubmission.caseNumber,
                caseSubmission.patient.lastName,
                caseSubmission.referringPathologist.lastName,
                caseSubmission.numberOfSlides,
                caseSubmission.numberOfBlocks,
            ]),
            margin: {top: 10, bottom: 10, left: 10, right: 10},
        });

        doc.save();

        setPdfLoading(false);
    };

    return (
        <LoadingButton
            size="small"
            loading={pdfLoading}
            disabled={!latestMailingListQuery.data || !casesQuery.data}
            startIcon={<PrintIcon/>}
            onClick={() => {
                if (latestMailingListQuery.data && casesQuery.data) {
                    void downloadPdf(latestMailingListQuery.data, casesQuery.data);
                }
            }}
        >
            Print &amp; Send
        </LoadingButton>
    );
};

export default PrintButton;
