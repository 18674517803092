import AddBoxIcon from '@mui/icons-material/AddBox';
import {Button, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import CreateMailingListDialog from '@/pages/MailingList/CreateMailingListDialog';
import LatestMailingList from '@/pages/MailingList/LatestMailingList';
import PrintButton from '@/pages/MailingList/PrintButton';

const MailingList = () : JSX.Element => {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{display: 'flex', pl: 2, pr: 2, pb: 1, mb: 2, borderBottom: 1, borderColor: 'divider'}}
            >
                <Typography variant="h6">
                    Mailing List
                </Typography>

                <Stack
                    direction={{xs: 'column', md: 'row'}}
                    spacing={{xs: 0, md: 2}}
                    alignItems="flex-start"
                >
                    <PrintButton/>
                    <Button
                        size="small"
                        startIcon={<AddBoxIcon/>}
                        onClick={() => {
                            setCreateDialogOpen(true);
                        }}
                    >
                        Create mailing list
                    </Button>
                </Stack>
            </Stack>

            <LatestMailingList/>
            <CreateMailingListDialog
                open={createDialogOpen}
                onClose={() => {
                    setCreateDialogOpen(false);
                }}
            />
        </>
    );
};

export default MailingList;
