import {z} from 'zod';

export const errorMap : z.ZodErrorMap = (issue, context) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
        return {message: 'Required'};
    }

    if (issue.code === z.ZodIssueCode.too_small && issue.minimum === 1 && issue.type === 'string') {
        return {message: 'Required'};
    }

    if (issue.code === z.ZodIssueCode.invalid_type && issue.expected === 'date' && issue.received === 'null') {
        return {message: 'Required'};
    }

    return {message: context.defaultError};
};
