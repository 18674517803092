import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import CaseListDesktopItem from '@/pages/MailingList/CaseListDesktopItem';
import type {CaseSubmission} from '@/types/case';

type Props = {
    mailingListId : string;
    caseSubmissions : CaseSubmission[];
};

const CaseListDesktop = ({mailingListId, caseSubmissions} : Props) : JSX.Element => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Case</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>RP</TableCell>
                        <TableCell># Slides</TableCell>
                        <TableCell># Blocks</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {caseSubmissions.map(caseSubmission => (
                        <CaseListDesktopItem
                            key={caseSubmission.id}
                            mailingListId={mailingListId}
                            caseSubmission={caseSubmission}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CaseListDesktop;
