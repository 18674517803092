import {LocalDate} from '@js-joda/core';
import {mapRawToPatient} from '@/mappers/patient';
import type {CaseSubmission, RawCaseSubmission, RawReceivedCase, ReceivedCase} from '@/types/case';

export const mapRawToCaseSubmission = (raw : RawCaseSubmission) : CaseSubmission => ({
    ...raw,
    dateOfSurgery: LocalDate.parse(raw.dateOfSurgery),
    patient: mapRawToPatient(raw.patient),
});

export const mapRawToReceivedCase = (raw : RawReceivedCase) : ReceivedCase => ({
    ...raw,
    dateOfSurgery: LocalDate.parse(raw.dateOfSurgery),
    finalizedOn: LocalDate.parse(raw.finalizedOn),
    patient: mapRawToPatient(raw.patient),
    dateOfReceipt: LocalDate.parse(raw.dateOfReceipt),
});
