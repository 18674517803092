import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, TableCell, TableRow} from '@mui/material';
import {useState} from 'react';
import DeleteCaseDialog from './DeleteCaseDialog';
import type {CaseSubmission} from '@/types/case';

type Props = {
    mailingListId : string;
    caseSubmission : CaseSubmission;
};

const CaseListDesktopItem = ({mailingListId, caseSubmission} : Props) : JSX.Element => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    return (
        <TableRow
            key={caseSubmission.id}
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
        >
            <TableCell>{caseSubmission.caseNumber}</TableCell>
            <TableCell>{caseSubmission.patient.lastName}</TableCell>
            <TableCell>{caseSubmission.referringPathologist.lastName}</TableCell>
            <TableCell>{caseSubmission.numberOfSlides}</TableCell>
            <TableCell>{caseSubmission.numberOfBlocks}</TableCell>
            <TableCell align="right">
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                        setDeleteDialogOpen(true);
                    }}
                >
                    <DeleteIcon/>
                </IconButton>
                <DeleteCaseDialog
                    open={deleteDialogOpen}
                    onClose={() => {
                        setDeleteDialogOpen(false);
                    }}
                    mailingListId={mailingListId}
                    caseId={caseSubmission.id}
                />
            </TableCell>
        </TableRow>
    );
};

export default CaseListDesktopItem;
