import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import {Card, CardContent, CardHeader, CircularProgress, IconButton, Typography} from '@mui/material';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useGetReportPdfMutation} from '@/mutations/case';
import type {ReceivedCase} from '@/types/case';

const dateFormatter = DateTimeFormatter.ofPattern('d MMMM y').withLocale(Locale.US);

type Props = {
    receivedCase : ReceivedCase;
};

const CaseList = ({receivedCase} : Props) : JSX.Element => {
    const getReportPdfMutation = useGetReportPdfMutation();
    const handleMutation = useHandleMutation();

    const downloadReportPdf = async () => {
        const result = await handleMutation(getReportPdfMutation, {caseId: receivedCase.id});

        if (result.success) {
            const url = URL.createObjectURL(result.data);
            window.open(url);
        }
    };

    return (
        <Card
            key={receivedCase.caseNumber}
            onClick={() => {
                if (receivedCase.reportPdfExists && !getReportPdfMutation.isLoading) {
                    void downloadReportPdf();
                }
            }}
            sx={{
                cursor: receivedCase.reportPdfExists && !getReportPdfMutation.isLoading ? 'pointer' : undefined,
            }}
        >
            <CardHeader
                title={receivedCase.caseNumber}
                subheader={receivedCase.finalizedOn.format(dateFormatter)}
                action={
                    receivedCase.reportPdfExists
                        ? (
                            <IconButton
                                aria-label="PDF"
                                disabled={getReportPdfMutation.isLoading}
                                onClick={downloadReportPdf}
                            >
                                {getReportPdfMutation.isLoading
                                    ? <CircularProgress size={24}/>
                                    : <PdfIcon/>
                                }
                            </IconButton>
                        )
                        : undefined
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    Patient: {receivedCase.patient.firstName} {receivedCase.patient.lastName}<br/>
                    Referring Pathologist: {receivedCase.referringPathologist.lastName}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CaseList;
