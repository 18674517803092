import {LoadingButton} from '@mui/lab';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useSnackbar} from 'notistack';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useDeleteCaseMutation} from '@/mutations/case';

type Props = {
    open : boolean;
    onClose : () => void;
    mailingListId : string;
    caseId : string;
};

const DeleteCaseDialog = ({open, onClose, mailingListId, caseId} : Props) : JSX.Element => {
    const deleteCaseMutation = useDeleteCaseMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const handleConfirm = async () => {
        if ((await handleMutation(deleteCaseMutation, {
            mailingListId,
            caseId,
        })).success) {
            enqueueSnackbar('Case has been deleted', {variant: 'success'});
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!deleteCaseMutation.isLoading) {
                    onClose();
                }
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Delete Case</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Are you sure that you want to delete this case?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={deleteCaseMutation.isLoading}
                    onClick={onClose}
                    color="secondary"
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={deleteCaseMutation.isLoading}
                    onClick={handleConfirm}
                    color="primary"
                >
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteCaseDialog;
