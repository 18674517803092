import type {UseQueryResult} from 'react-query';
import {useQuery} from 'react-query';
import {useIdentity} from '@/components/IdentityProvider';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToMailingList} from '@/mappers/mailing-list';
import type {MailingList, RawMailingList} from '@/types/mailing-list';
import {apiUrl} from '@/utils/api';

export const useLatestMailingListQuery = () : UseQueryResult<MailingList | null> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();

    return useQuery([activeIdentity.id.toString(), 'mailing-lists', 'latest'], async ({signal}) => {
        const response = await fetch(apiUrl('/mailing-lists/latest').toString(), {
            signal,
            headers: {
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new Error('Unable to fetch latest mailing list');
        }

        if (response.status === 204) {
            return null;
        }

        const rawMailingList = await response.json() as RawMailingList;
        return mapRawToMailingList(rawMailingList);
    });
};
