import type {UseQueryResult} from 'react-query';
import {useQuery} from 'react-query';
import {useIdentity} from '@/components/IdentityProvider';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToCaseSubmission, mapRawToReceivedCase} from '@/mappers/case';
import type {CaseSubmission, RawCaseSubmission, RawReceivedCase, ReceivedCase} from '@/types/case';
import type {CollectionResponse} from '@/utils/api';
import {apiUrl} from '@/utils/api';

export const useReceivedCasesQuery = (justIn : boolean) : UseQueryResult<ReceivedCase[]> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();

    return useQuery([activeIdentity.id.toString(), 'cases', justIn ? 'just-in' : 'your-practice'], async ({signal}) => {
        const url = apiUrl('/cases');
        url.searchParams.set('justIn', justIn ? 'true' : 'false');

        const response = await fetch(url.toString(), {
            signal,
            headers: {
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new Error('Unable to fetch cases');
        }

        const rawCases = (await response.json() as CollectionResponse<RawReceivedCase>).items;
        return rawCases.map(mapRawToReceivedCase);
    });
};

export const useCaseSubmissionsQuery = (mailingListId ?: string) : UseQueryResult<CaseSubmission[]> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();

    return useQuery([activeIdentity.id.toString(), 'mailing-lists', mailingListId, 'cases'], async ({signal}) => {
        if (!mailingListId) {
            throw new Error('Mailing list ID not supplied');
        }

        const response = await fetch(apiUrl(`/mailing-lists/${mailingListId}/cases`).toString(), {
            signal,
            headers: {
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new Error('Unable to fetch cases');
        }

        const rawCases = (await response.json() as CollectionResponse<RawCaseSubmission>).items;
        return rawCases.map(mapRawToCaseSubmission);
    }, {
        enabled: mailingListId !== undefined,
    });
};
