import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, List, ListItem, ListItemText} from '@mui/material';
import type {CaseSubmission} from '@/types/case';

type Props = {
    caseSubmissions : CaseSubmission[];
};

const CaseListMobile = ({caseSubmissions} : Props) : JSX.Element => {
    return (
        <List>
            {caseSubmissions.map(caseSubmission => (
                <ListItem
                    key={caseSubmission.id}
                    secondaryAction={(
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon/>
                        </IconButton>
                    )}
                >
                    <ListItemText
                        primary={caseSubmission.caseNumber}
                        secondary={
                            `${caseSubmission.patient.lastName} / ${caseSubmission.referringPathologist.lastName}`
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default CaseListMobile;
