import type {UseMutationResult} from 'react-query';
import {useMutation, useQueryClient} from 'react-query';
import {useIdentity} from '@/components/IdentityProvider';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToMailingList} from '@/mappers/mailing-list';
import type {MailingList, RawMailingList} from '@/types/mailing-list';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type CreateMailingListValues = Pick<MailingList, 'shippingDate' | 'carrier' | 'trackingNumber'>;

export const useCreateMailingListMutation = () : UseMutationResult<
    MailingList,
    unknown,
    CreateMailingListValues
> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();
    const queryClient = useQueryClient();

    return useMutation(async (values : CreateMailingListValues) => {
        const response = await fetch(apiUrl('/mailing-lists').toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create mailing list');
        }

        const rawMailingList = await response.json() as RawMailingList;
        return mapRawToMailingList(rawMailingList);
    }, {
        onSuccess: mailingList => {
            queryClient.setQueryData([activeIdentity.id.toString(), 'mailing-lists', 'latest'], mailingList);
        },
    });
};

type UpdateTrackingNumberValues = Pick<MailingList, 'id' | 'trackingNumber'>;

export const useUpdateTrackingNumberMutation = () : UseMutationResult<
    MailingList,
    unknown,
    UpdateTrackingNumberValues
> => {
    const fetch = useOidcFetch();
    const {activeIdentity} = useIdentity();
    const queryClient = useQueryClient();

    return useMutation(async (values : UpdateTrackingNumberValues) => {
        const response = await fetch(apiUrl(`/mailing-lists/${values.id}/tracking-number`).toString(), {
            method: 'PATCH',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'x-identity-id': activeIdentity.id.toString(),
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to update tracking number');
        }

        const rawMailingList = await response.json() as RawMailingList;
        return mapRawToMailingList(rawMailingList);
    }, {
        onSuccess: mailingList => {
            queryClient.setQueryData([activeIdentity.id.toString(), 'mailing-lists', 'latest'], mailingList);
        },
    });
};
