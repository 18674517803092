import {DateTimeFormatter} from '@js-joda/core';
import {Locale} from '@js-joda/locale_en-us';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NumbersIcon from '@mui/icons-material/Numbers';
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {useState} from 'react';
import CaseList from './CaseList';
import RetryableError from '@/components/RetryableError';
import SubmitCaseDialog from '@/pages/MailingList/SubmitCaseDialog';
import UpdateTrackingNumberDialog from '@/pages/MailingList/UpdateTrackingNumberDialog';
import {useLatestMailingListQuery} from '@/queries/mailing-list';

const dateFormatter = DateTimeFormatter.ofPattern('d MMMM y').withLocale(Locale.US);

const LatestMailingList = () : JSX.Element => {
    const latestMailingListQuery = useLatestMailingListQuery();
    const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
    const [updateTrackingNumberDialogOpen, setUpdateTrackingNumberDialogOpen] = useState(false);

    if (latestMailingListQuery.data === null) {
        return (
            <Alert severity="info">
                You haven't created a mailing list yet.
            </Alert>
        );
    }

    if (latestMailingListQuery.data) {
        const mailingList = latestMailingListQuery.data;

        return (
            <Card>
                <CardContent>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemIcon><CalendarMonthIcon/></ListItemIcon>
                            <ListItemText
                                primary={mailingList.shippingDate.format(dateFormatter)}
                                secondary="Shipping date"
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon><LocalShippingIcon/></ListItemIcon>
                            <ListItemText primary={mailingList.carrier} secondary="Carrier"/>
                        </ListItem>
                        <ListItem disablePadding secondaryAction={(
                            <IconButton edge="end" onClick={() => {
                                setUpdateTrackingNumberDialogOpen(true);
                            }}>
                                <EditIcon/>
                            </IconButton>
                        )}>
                            <ListItemIcon><NumbersIcon/></ListItemIcon>
                            <ListItemText
                                primary={mailingList.trackingNumber === '' ? 'Undefined' : mailingList.trackingNumber}
                                secondary="Tracking number"
                            />
                        </ListItem>
                    </List>
                    <UpdateTrackingNumberDialog
                        mailingList={mailingList}
                        open={updateTrackingNumberDialogOpen}
                        onClose={() => {
                            setUpdateTrackingNumberDialogOpen(false);
                        }}
                    />
                </CardContent>
                <CardActions>
                    <Button onClick={() => {
                        setSubmitDialogOpen(true);
                    }}>
                        Add a Case
                    </Button>
                    <SubmitCaseDialog
                        open={submitDialogOpen}
                        onClose={() => {
                            setSubmitDialogOpen(false);
                        }}
                        mailingListId={mailingList.id}
                    />
                </CardActions>
                <Divider/>
                <CaseList mailingListId={mailingList.id}/>
            </Card>
        );
    }

    if (latestMailingListQuery.isError) {
        return (
            <RetryableError
                message="Failed to load mailing list."
                onRetry={() => void latestMailingListQuery.refetch()}
            />
        );
    }

    return <LinearProgress/>;
};

export default LatestMailingList;
