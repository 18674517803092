import {zodResolver} from '@hookform/resolvers/zod/dist/zod';
import {LocalDate, nativeJs} from '@js-joda/core';
import {LoadingButton} from '@mui/lab';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from '@mui/material';
import {RhfDatePicker, RhfTextField} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateMailingListMutation} from '@/mutations/mailing-list';
import {errorMap} from '@/utils/zod';

const schema = z.object({
    shippingDate: z.date(),
    carrier: z.string().trim().min(1).max(20),
    trackingNumber: z.string().trim().max(40).default(''),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    open : boolean;
    onClose : () => void;
};

const CreateMailingListDialog = ({open, onClose} : Props) : JSX.Element => {
    const createMailingListMutation = useCreateMailingListMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
    });

    useEffect(() => {
        if (!open) {
            form.reset();
        }
    }, [open]);

    const handleSubmit = async (values : FormValues) => {
        if ((await handleMutation(createMailingListMutation, {
            ...values,
            shippingDate: LocalDate.from(nativeJs(values.shippingDate)),
        })).success) {
            enqueueSnackbar('Mailing list has been created', {variant: 'success'});
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!form.formState.isSubmitting) {
                    onClose();
                }
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <DialogTitle>Create Mailing List</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText sx={{mb: 2}}>
                        Creating a new mailing list will replace the existing mailing list.
                    </DialogContentText>
                    <Stack spacing={2}>
                        <RhfDatePicker
                            control={form.control}
                            name="shippingDate"
                            label="Shipping date"
                            textFieldProps={{
                                fullWidth: true,
                                required: true,
                            }}
                        />
                        <RhfTextField
                            control={form.control}
                            name="carrier"
                            label="Carrier"
                            required
                            fullWidth
                        />
                        <RhfTextField
                            control={form.control}
                            name="trackingNumber"
                            label="Tracking number"
                            fullWidth
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={form.formState.isSubmitting}
                        onClick={onClose}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={form.formState.isSubmitting}
                        type="submit"
                        color="primary"
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </DialogForm>
        </Dialog>
    );
};

export default CreateMailingListDialog;
