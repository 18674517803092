import {zodResolver} from '@hookform/resolvers/zod/dist/zod';
import {LoadingButton} from '@mui/lab';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useUpdateTrackingNumberMutation} from '@/mutations/mailing-list';
import type {MailingList} from '@/types/mailing-list';
import {errorMap} from '@/utils/zod';

const schema = z.object({
    trackingNumber: z.string().trim().max(40).default(''),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    mailingList : MailingList;
    open : boolean;
    onClose : () => void;
};

const UpdateTrackingNumberDialog = ({mailingList, open, onClose} : Props) : JSX.Element => {
    const updateTrackingNumberMutation = useUpdateTrackingNumberMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
    });

    useEffect(() => {
        if (!open) {
            form.reset({
                trackingNumber: mailingList.trackingNumber,
            });
        }
    }, [open, mailingList]);

    const handleSubmit = async (values : FormValues) => {
        if ((await handleMutation(updateTrackingNumberMutation, {
            ...values,
            id: mailingList.id,
        })).success) {
            enqueueSnackbar('Tracking number has been updated', {variant: 'success'});
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!form.formState.isSubmitting) {
                    onClose();
                }
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <DialogTitle>Update Tracking Number</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <RhfTextField
                            control={form.control}
                            name="trackingNumber"
                            label="Tracking number"
                            fullWidth
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={form.formState.isSubmitting}
                        onClick={onClose}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={form.formState.isSubmitting}
                        type="submit"
                        color="primary"
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </DialogForm>
        </Dialog>
    );
};

export default UpdateTrackingNumberDialog;
