import {Box, Tab, Tabs, Typography} from '@mui/material';
import {useState} from 'react';
import CaseList from './CaseList';

const Cases = () : JSX.Element => {
    const [currentTab, setCurrentTab] = useState('just-in');

    return (
        <>
            <Typography variant="h6" sx={{pl: 2, pr: 2, pb: 1, mb: 2, borderBottom: 1, borderColor: 'divider'}}>
                Cases
            </Typography>

            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => {
                        setCurrentTab(newValue as string);
                    }}
                >
                    <Tab value="just-in" label="Just In"/>
                    <Tab value="your-practice" label="Your Practice"/>
                </Tabs>
            </Box>

            <Box sx={{pt: 2, pb: 2}}>
                <CaseList justIn={currentTab === 'just-in'}/>
            </Box>
        </>
    );
};

export default Cases;
