import {Alert, LinearProgress, Stack} from '@mui/material';
import RetryableError from '@/components/RetryableError';
import CaseCard from '@/pages/Cases/CaseCard';
import {useReceivedCasesQuery} from '@/queries/case';

type Props = {
    justIn : boolean;
};

const CaseList = ({justIn} : Props) : JSX.Element => {
    const casesQuery = useReceivedCasesQuery(justIn);

    if (casesQuery.data) {
        if (casesQuery.data.length === 0) {
            return (
                <Alert severity="info">
                    There are no cases in this category.
                </Alert>
            );
        }

        return (
            <Stack spacing={2}>
                {casesQuery.data.map(receivedCase => (
                    <CaseCard key={receivedCase.id} receivedCase={receivedCase}/>
                ))}
            </Stack>
        );
    }

    if (casesQuery.isError) {
        return <RetryableError message="Failed to load cases." onRetry={() => void casesQuery.refetch()}/>;
    }

    return <LinearProgress/>;
};

export default CaseList;
