import {Alert, LinearProgress, useMediaQuery, useTheme} from '@mui/material';
import CaseListDesktop from './CaseListDesktop';
import CaseListMobile from './CaseListMobile';
import RetryableError from '@/components/RetryableError';
import {useCaseSubmissionsQuery} from '@/queries/case';

type Props = {
    mailingListId : string;
};

const CaseList = ({mailingListId} : Props) : JSX.Element => {
    const casesQuery = useCaseSubmissionsQuery(mailingListId);
    const theme = useTheme();
    const useDesktopCaseList = useMediaQuery(theme.breakpoints.up('lg'));

    if (casesQuery.data) {
        if (casesQuery.data.length === 0) {
            return (
                <Alert severity="info" sx={{border: 0, borderRadius: 0}}>
                    You haven't submitted any cases yet.
                </Alert>
            );
        }

        if (useDesktopCaseList) {
            return (
                <CaseListDesktop mailingListId={mailingListId} caseSubmissions={casesQuery.data}/>
            );
        }

        return (
            <CaseListMobile caseSubmissions={casesQuery.data}/>
        );
    }

    if (casesQuery.isError) {
        return <RetryableError message="Failed to load cases." onRetry={() => void casesQuery.refetch()}/>;
    }

    return <LinearProgress/>;
};

export default CaseList;
