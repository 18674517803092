import {Route, Routes} from 'react-router-dom';
import Layout from './Layout';
import Cases from './pages/Cases';
import MailingList from './pages/MailingList';

const App = () : JSX.Element => {
    return (
        <Routes>
            <Route path="/:identityId" element={<Layout/>}>
                <Route index element={<Cases/>}/>
                <Route path="mailing-list" element={<MailingList/>}/>
            </Route>
        </Routes>
    );
};

export default App;
