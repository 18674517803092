import {zodResolver} from '@hookform/resolvers/zod/dist/zod';
import {LocalDate, nativeJs} from '@js-joda/core';
import {LoadingButton} from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Typography,
} from '@mui/material';
import {RhfDatePicker, RhfTextField} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import {useEffect, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import DialogForm from '@/components/DialogForm';
import ReferringPathologistsSelect from '@/components/ReferringPathologistsSelect/ReferringPathologistsSelect';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useSubmitCaseMutation} from '@/mutations/case';
import {errorMap} from '@/utils/zod';

const schema = z.object({
    caseNumber: z.string().trim().min(1).max(40),
    patient: z.object({
        firstName: z.string().trim().min(1).max(40),
        lastName: z.string().trim().min(1).max(40),
        middleInitial: z.string().trim().max(4).default(''),
        sex: z.enum(['male', 'female', 'not-disclosed']),
        dateOfBirth: z.date(),
    }),
    dateOfSurgery: z.date(),
    doctor: z.string().trim().max(80).default(''),
    referringPathologist: z.object({
        id: z.string().regex(/^\d+$/),
    }),
    numberOfSlides: z.string()
        .regex(/^\d+$/, 'Must be a positive number')
        .default('0')
        .transform(value => parseInt(value, 10))
        .refine(value => value > 0, 'Must be greater than zero'),
    numberOfBlocks: z.string().regex(/^\d+$/, 'Must be a number').default('0').transform(value => parseInt(value, 10)),
    clinicalInformation: z.string().trim().default(''),
    materialsSent: z.string().trim().default(''),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    open : boolean;
    onClose : () => void;
    mailingListId : string;
};

const SubmitCaseDialog = ({open, onClose, mailingListId} : Props) : JSX.Element => {
    const submitCaseMutation = useSubmitCaseMutation();
    const handleMutation = useHandleMutation();
    const {enqueueSnackbar} = useSnackbar();
    const continueAfterSubmit = useRef(false);

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
    });

    useEffect(() => {
        if (!open) {
            form.reset();
        }
    }, [open]);

    const handleSubmit = async (values : FormValues) => {
        if ((await handleMutation(submitCaseMutation, {
            ...values,
            patient: {
                ...values.patient,
                dateOfBirth: LocalDate.from(nativeJs(values.patient.dateOfBirth)),
            },
            dateOfSurgery: LocalDate.from(nativeJs(values.dateOfSurgery)),
            mailingListId,
        })).success) {
            enqueueSnackbar('Case has been submitted', {variant: 'success'});

            if (continueAfterSubmit.current) {
                form.reset();
            } else {
                onClose();
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!form.formState.isSubmitting) {
                    onClose();
                }
            }}
            maxWidth="lg"
            fullWidth
        >
            <DialogForm onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <DialogTitle>Submit Case</DialogTitle>
                <DialogContent dividers>
                    <Typography sx={{mt: 3, mb: 1}}>Patient</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <RhfTextField
                                control={form.control}
                                name="patient.lastName"
                                label="Last Name"
                                required
                                fullWidth
                                inputProps={{maxLength: 40}}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <RhfTextField
                                control={form.control}
                                name="patient.firstName"
                                label="First Name"
                                required
                                fullWidth
                                inputProps={{maxLength: 40}}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <RhfTextField
                                control={form.control}
                                name="patient.middleInitial"
                                label="MI"
                                fullWidth
                                inputProps={{maxLength: 4}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RhfTextField
                                control={form.control}
                                name="patient.sex"
                                label="Sex"
                                required
                                fullWidth
                                select
                            >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="not-disclosed">Not Disclosed</MenuItem>
                            </RhfTextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RhfDatePicker
                                control={form.control}
                                name="patient.dateOfBirth"
                                label="Date of Birth"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                }}
                                views={['year', 'month', 'day']}
                                openTo="year"
                            />
                        </Grid>
                    </Grid>

                    <Typography sx={{mt: 3, mb: 1}}>Case</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RhfTextField
                                control={form.control}
                                name="caseNumber"
                                label="Case Number"
                                required
                                fullWidth
                                inputProps={{maxLength: 40}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RhfDatePicker
                                control={form.control}
                                name="dateOfSurgery"
                                label="Date of Surgery (Collection)"
                                textFieldProps={{
                                    fullWidth: true,
                                    required: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReferringPathologistsSelect
                                control={form.control}
                                name="referringPathologist.id"
                                label="Referring Pathologist"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RhfTextField
                                control={form.control}
                                name="doctor"
                                label="Doctor (Clinician)"
                                fullWidth
                                inputProps={{maxLength: 80}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RhfTextField
                                control={form.control}
                                name="numberOfSlides"
                                label="Number of Slides"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RhfTextField
                                control={form.control}
                                name="numberOfBlocks"
                                label="Number of Blocks"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RhfTextField
                                control={form.control}
                                name="clinicalInformation"
                                label="Clinical Information"
                                fullWidth
                                multiline
                                rows={3}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <RhfTextField
                                control={form.control}
                                name="materialsSent"
                                label="Description of Materials Sent"
                                fullWidth
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={form.formState.isSubmitting}
                        onClick={onClose}
                        color="secondary"
                    >
                        Don't Save
                    </Button>
                    <LoadingButton
                        loading={form.formState.isSubmitting}
                        type="submit"
                        color="primary"
                        onClick={() => {
                            continueAfterSubmit.current = false;
                        }}
                    >
                        Save &amp; Close
                    </LoadingButton>
                    <LoadingButton
                        loading={form.formState.isSubmitting}
                        type="submit"
                        color="primary"
                        onClick={() => {
                            continueAfterSubmit.current = true;
                        }}
                    >
                        Save &amp; Add Another
                    </LoadingButton>
                </DialogActions>
            </DialogForm>
        </Dialog>
    );
};

export default SubmitCaseDialog;
