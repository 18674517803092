import {Box, CircularProgress} from '@mui/material';

const FullPageLoadingIndicator = () : JSX.Element => {
    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <CircularProgress size={80}/>
        </Box>
    );
};

export default FullPageLoadingIndicator;
