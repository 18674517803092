import {Auth0Provider} from '@auth0/auth0-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {SnackbarProvider} from 'notistack';
import {StrictMode} from 'react';
import {render} from 'react-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import IdentityProvider from '@/components/IdentityProvider';
import {OidcSecure} from '@/components/Oidc';
import '@js-joda/timezone';

const theme = createTheme();
const queryClient = new QueryClient();

render(
    (
        <StrictMode>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Auth0Provider
                    domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
                    clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
                    redirectUri={window.location.origin}
                    audience={import.meta.env.VITE_APP_AUTH0_AUDIENCE}
                    useRefreshTokens
                >
                    <OidcSecure>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <QueryClientProvider client={queryClient}>
                                <SnackbarProvider maxSnack={1}>
                                    <BrowserRouter>
                                        <IdentityProvider>
                                            <App/>
                                        </IdentityProvider>
                                    </BrowserRouter>
                                </SnackbarProvider>
                            </QueryClientProvider>
                        </LocalizationProvider>
                    </OidcSecure>
                </Auth0Provider>
            </ThemeProvider>
        </StrictMode>
    ),
    document.getElementById('root')
);
