import {useAuth0} from '@auth0/auth0-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import type {
    IconButtonProps} from '@mui/material';
import {
    AppBar,
    Box,
    Container,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    Toolbar,
    Typography,
} from '@mui/material';
import {useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import logoImage from './logo.svg';
import {useIdentity} from '@/components/IdentityProvider';

type ExpandMoreProps = IconButtonProps & {
    expand : boolean;
};

const ExpandMore = styled((props : ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other}/>;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const drawerWidth = 240;

const Layout = () : JSX.Element => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const {logout, user} = useAuth0();
    const {activeIdentity, identities, selectIdentity} = useIdentity();
    const [showIdentities, setShowIdentities] = useState(false);

    const navigate = useNavigate();

    if (!user) {
        throw new Error('Layout can only be used with signed in user');
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Stack sx={{p: 2}} direction="row" alignItems="flex-start">
                <Box
                    sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        cursor: identities.length > 1 ? 'pointer' : 'auto',
                    }}
                    onClick={() => {
                        if (identities.length > 1) {
                            setShowIdentities(!showIdentities);
                        }
                    }}
                >
                    <Typography noWrap>
                        {activeIdentity.firstName} {activeIdentity.lastName}
                    </Typography>
                    <Typography noWrap variant="body2">
                        {user.email}
                    </Typography>
                    <Typography noWrap variant="body2">
                        {activeIdentity.orgUsername}
                    </Typography>
                </Box>
                {identities.length > 1 && (
                    <ExpandMore
                        expand={showIdentities}
                        edge="end"
                        sx={{mt: -1}}
                        onClick={() => {
                            setShowIdentities(!showIdentities);
                        }}
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                )}
            </Stack>
            <Divider/>
            {showIdentities
                ? (
                    <List>
                        {identities.map(identity => (
                            <ListItem disablePadding key={identity.id}>
                                <ListItemButton onClick={() => {
                                    setShowIdentities(false);
                                    selectIdentity(identity.id);
                                }}>
                                    <ListItemIcon>
                                        {identity.id === activeIdentity.id
                                            ? <PersonIcon/>
                                            : <PersonOutlineIcon/>
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={identity.orgUsername}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )
                : (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setMobileOpen(false);
                                navigate(`/${activeIdentity.id}`);
                            }}>
                                <ListItemIcon>
                                    <ListIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Case List"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setMobileOpen(false);
                                navigate(`/${activeIdentity.id}/mailing-list`);
                            }}>
                                <ListItemIcon>
                                    <MailIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Submit Cases"/>
                            </ListItemButton>
                        </ListItem>
                        <Divider/>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                setMobileOpen(false);
                                logout({returnTo: window.location.origin});
                            }}>
                                <ListItemIcon>
                                    <LogoutIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Log Out"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                )
            }
        </div>
    );

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    marginLeft: {sm: `${drawerWidth}px`},
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{marginRight: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Box component="img" src={logoImage} alt="Logo" height={32} sx={{mr: 2}}/>
                    <Typography variant="h6" noWrap component="div">
                        Dermatopathology Consultations
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: {sx: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, paddingTop: 4, paddingBottom: 4, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >
                <Toolbar/>
                <Container maxWidth="lg">
                    <Outlet/>
                </Container>
            </Box>
        </Box>
    );
};

export default Layout;
